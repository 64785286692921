.main-div {
    padding: 0px 5%;
}

.search-btn {
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border: 2px solid #6E48AA;
    border-radius: 40px;
    color: #6E48AA;
    cursor: pointer;
    display: flex;
    align-self: center;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    margin: 0 auto;
    padding: 5px 15px;
    text-decoration: none;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.search-btn:hover {
    color: #fff;
    outline: 0;
    box-shadow: 0 0 40px 40px #6E48AA inset;
}